import * as React from "react";
import { graphql } from 'gatsby';
import Blog, { BlogSubHeader, BlogUnorderListItem, BlogUnorderList } from '../../components/Blog';
import { Paragraph } from "../../components/Common";
import { HE } from '../../blogs/typical-front-end-day';
import { WhatDoseFullstackDeveloper, DesignRules, TypicalFrontEndDay, WebDevelopmentProcesses } from '../../blogs';
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { BLOGS_ORIGINAL_BREADCRUMBS } from '../../constants';
import { useArticelSchema } from '../../hooks/useArticelSchema';

const articleSchemaData = {
  headline: "Typical Front End Day",
  description: "SEO description Typical Front End Day",
  image: "https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Ftypical-front-end-day.jpg?alt=media&token=ed974101-5273-49e3-98ba-6e2168bff9e5",
  datePublished: '2022-03-20'
};

const TypicalFrontEndDayBlog = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema(TypicalFrontEndDay.title, BLOGS_ORIGINAL_BREADCRUMBS);
  const articelSchema = useArticelSchema(articleSchemaData);

  return (
    <Blog
      url={TypicalFrontEndDay.url}
      banner="/typical-front-end-day/banner.jpg"
      relatedBlogs={[DesignRules, WhatDoseFullstackDeveloper, WebDevelopmentProcesses]}
      title={TypicalFrontEndDay.title}
      seo={{
        title: 'SEO title Typical Front End Day',
        description: TypicalFrontEndDay.description,
        image: 'https://firebasestorage.googleapis.com/v0/b/site-ab026.appspot.com/o/blogs%2Ftypical-front-end-day.jpg?alt=media&token=ed974101-5273-49e3-98ba-6e2168bff9e5',
        breadcrumbsElementsSchema,
        articelSchema,
      }}
    >
      {HE.section1.p.map(p => <Paragraph>{p}</Paragraph>)}


      <BlogSubHeader>
        {HE.section2.h}
      </BlogSubHeader>
      {HE.section2.p.map(p => <Paragraph>{p}</Paragraph>)}

      <BlogSubHeader>
        {HE.section3.h}
      </BlogSubHeader>
      {HE.section3.p.map(p => <Paragraph>{p}</Paragraph>)}

      <Paragraph>
        <BlogUnorderList>
          {HE.section3.ul.map(p => (
            <BlogUnorderListItem>
              {p}
            </BlogUnorderListItem>
          ))}
        </BlogUnorderList>
      </Paragraph>


      {HE.section4.p.map(p => <Paragraph>{p}</Paragraph>)}

      <Paragraph>
        <BlogUnorderList>
          {HE.section4.ul.map(p => (
            <BlogUnorderListItem>
              {p}
            </BlogUnorderListItem>
          ))}
        </BlogUnorderList>
      </Paragraph>


      <BlogSubHeader>
        {HE.section5.h}
      </BlogSubHeader>
      {HE.section5.p.map(p => <Paragraph>{p}</Paragraph>)}
    </Blog>
  )
}

export default TypicalFrontEndDayBlog;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;