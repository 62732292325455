import React from "react"
import { Link } from 'src/components/Common';
import { URL } from '../url';

export const HE = {
  section1: {
    p: [
      "בית התוכנה המוביל אפספורס מתמחה בפיתוח מוצרים טכנולוגיים חדשים ורענון מוצרים ואתרים קיימים. צוות הפיתוח שלנו מונה מפתחים מהשורה הראשונה בארץ, אנחנו משתמשים רק בטכנולוגיות עדכניות והמתקדמות ביותר על מנת להבטיח ללקוחות שלנו אתרי WEB, תוכנות, אפליקציות ומוצרים טכנולוגיים איכותיים, בהתאמה מושלמת לצורכי הלקוח.",
      <>
        {`מעטפת השירותים שלנו כוללת בין היתר `}
        <Link to={URL.FRONT_END_SERVICE} active>פיתוח Front End</Link>,
        {` `}
        {`כלומר פיתוח צד לקוח. Front End (פרונט אנד) הוא מונח מוכר בתחום הפיתוח המתאר את החלק הוויזואלי והנראה לעין באתר אינטרנט, באפליקציה או בתוכנה, כלומר החלק המיועד עבור משתמש הקצה. מפתח פרונט אנד מתמחה בתכנון, עיצוב ובניית ממשקי משתמש וביצירת חוויית משתמש ייחודית ומרגשת, או בקצרה UI/UX. במאמר זה נציג בפניכם איך נראה יום שגרתי בחייו של מפתח פרונט אנד, ובכל שאלה או בקשה הנכם מוזמנים לפנות אלינו ואנחנו נשמח לסייע לכם.`}
      </>
    ],
  },
  section2: {
    h: "פיתוח תוכנה – צד לקוח",
    p: [
      "מפתח תוכנה, המכונה בתעשיית ההייטק מתכנת, הוא שם כללי לעובדי הייטק העוסקים בפיתוח תוכנות, יישומים, אתרים ואפליקציות. עולם פיתוח התוכנה בהייטק הוא רחב מאוד, יש תחומים שונים הנבדלים זה מזה בשפות הפיתוח ופלטפורמות על גביהן מפתחים את המוצר הטכנולוגי. פיתוח פרונט אנד הוא אחד מתחומי הפיתוח הנפוצים והמבוקשים ביותר.",
      "מפתחי Front End הנקראים בתעשיית ההייטק גם מפתחי צד לקוח, הם מתכנתים בעלי ידע וכלים לתכנות של ממשקי משתמש (UI). הם אחראים על עיצוב הממשקים והנראות הכללית של הממשק של היישום, התוכנה או האתר. מפתחי פרונט אנד כותבים קודים הרצים על דפדפני האינטרנט השונים במחשבים ובמכשירים הסלולריים של המשתמשים.",
      "באופן כללי, פיתוח Front End מתמקד בנראות הממשקים בצד הלקוח, כלומר כפתורים, תפריטים, לחצנים, טקסטים, צבעים ועוד."
    ],
  },
  section3: {
    h: "יום שגרתי בחייו של מפתח פרונט אנד",
    p: [
      "מפתחי פרונט אנד אחראים על פיתוח ועיצוב החלקים הנראים למשתמשי הקצה. לדוגמא, כאשר גולשים לאתר אינטרנט מסוים, מפתח פרונט אנד היה אחראי על המראה הכללי של האתר, כולל טקסט, תמונות, עיצוב אינטראקטיבי, כפתורים ועוד. חשוב לדעת כי מפתח פרונט אנד עובד בשיתוף פעולה הדוק עם אנשי מקצוע נוספים האחראים על פיתוח האתר או היישום, לרבות מעצבים גרפיים, כותבי תוכן, מפתחי בק אנד (Back End) ועוד. המפתח משלב את כל האלמנטים יחד והופך אותם לממשק מעוצב וידידותי למשתמשי הקצה.",
      "מפתחי פרונט אנד מתמקדים בעבודתם היומית והשוטפת בשני תחומים מרכזיים:",
    ],
    ul: [
      <>
        <strong>ממשק משתמש (UI – User Interface)</strong>
        {` - נראות המוצר הטכנולוגי (אתר, אפליקציה, תוכנה וכו'), לרבות עיצוב הממשק, בחירת צבעים, כפתורים, תפריטים וכו'.`}
      </>,
      <>
        <strong>חוויית משתמש (UX – User Experience)</strong>
        {` - פיתוח ממשקים נוחים המבטיחים חוויית משתמש אופטימלית ומיטבית.`}
      </>,
    ]
  },
  section4: {
    p: [
      "יום טיפוסי בחייו של מפתח פרונט אנד כולל את תחומי האחריות הבאים:",
    ],
    ul: [
      <>
        <strong>כתיבת קוד</strong>
        {` - המפתח כותב קוד בשפת תכנות פופולרית, בדגש על ג'אווה סקריפט, HTML ו-CSS. הקוד נועד להגדיר לאתר או ליישום היכן למקם תכנים, כלומר פריסת העמוד, מיקום נכון של התוכן בדף, בחירת מיקומים לתכנים השונים המופיעים בדף ייעודי ועוד.`}
      </>,
      <>
        <strong>הגדרת אלמנטים שונים לתצוגה בממשק המשתמש</strong>
        {` - המפתח אחראי על הגדרת התצוגה של האלמנטים השונים כגון: גודל וצבע גופן לעיצוב הטקסט, מיקום התמונות בדף או בחלון התוכנה, הגדרת צבעי רקע או תמונת רקע וכיוצא בזה.`}
      </>,
      <>
        <strong>פיתוח אלמנטים אינטראקטיביים</strong>
        {` - מפתח פרונט אנד אחראי גם על יצירת כפתורים לחיצים ואלמנטים עיצוביים שונים במטרה למשוך את הגולשים והמשתמשים לבצע פעילות מסוימת באתר, בתוכנה או באפליקציה. אלמנטים אינטראקטיביים יוצרים מעורבות גבוהה של המשתמשים ומספקים חוויה ייחודית ומלהיבה.`}
      </>,
      <>
        <strong>הטמעת תמונות וקבצי וידאו</strong>
        {` - תחזוקה של דפים קיימים ושדרוג ממשקים של יישומים ותוכנות, כולל הטמעת תכנים, תמונות וקבצי וידאו.`}
      </>,
    ]
  },
  section5: {
    h: "אילו כלים נדרשים לפיתוח Front End?",
    p: [
      "הבסיס לפיתוח פרונט אנד הוא שפות כמו HTML, CSS ו-JavaScript. מפתחי פרונט אנד חייבים לשלוט בשפות שציינו, ומעבר לכך עליהם להכיר פלטפורמות וטכנולוגיות פיתוח נוספות על מנת לבצע את מלאכתם, במיוחד כאשר עליהם לפתח אתרים ויישומים למובייל, מאחר ויש כלים ייעודיים לפיתוח לאנדרואיד וכלים אחרים המיועדים לפיתוח לאייפון.",
      "פיתוח Front End מחייב הכרות מעמיקה עם Framework כדי לפתח בצורה מהירה ויעילה יותר. הפריימוורק הוא למעשה אוסף של קודים מוכנים, כלים ושירותים שונים, וכך בעת הפיתוח אין צורך לייצר קוד מאפס. הפריימוורק המתאימים לפיתוח צד לקוח הם Angular ו- React, אך יש פלטפורמות נוספות שכדאי להכיר.",
      <>
        {`לשאלות נוספות בתחום הפיתוח בפרונט אנד הנכם מוזמנים לפנות לחברת `}
        <Link to={URL.HOME} active>אפספורס – חברת פיתוח תוכנה</Link>
        {` מתמחה בין היתר בפיתוח פרונט אנד,`}
        <Link to={URL.FULL_STACK_SERVICE} active>פיתוח Full Stack</Link>
        {`, `}
        <Link to={URL.SAAS_CONSULTING_SERVICE} active>ייעוץ פיתוח Saas</Link>
        {`, `}
        <Link to={URL.UI_UX_DESIGN_SERVICE} active>ואפיון ועיצוב UI/UX</Link>.
      </>,
    ],
  },
};